
import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import MicroService from "@/models/MicroService";
import MicroserviceRepository from "@/api/repositories/MicroserviceRepository";
import {SubscriptionStatus} from "@/enum/SubscriptionStatus.enum";
import {ROUTE_MICRO_SERVICE_SUMMARY} from "@/router/routes";

enum TAB_STATES {
  ALL = 'ALL',
  MY = 'MY'
}

@Component({
  components: {
    MicroServiceCard: () => import(
        /* webpackChunkName: "MicroServiceCard" */
        '@/components/MicroServices/MicroServiceCard.component.vue'
        ),
    MicroServiceHeaderCard: () => import(
        /* webpackChunkName: "MicroServiceHeaderCard" */
        '@/components/MicroServices/MicroServiceHeaderCard.component.vue'
        ),
    MicroServiceDetailCard: () => import(
        /* webpackChunkName: "MicroServiceDetailCard" */
        '@/components/MicroServices/BuyMicroService.component.vue'
        ),
    QuitMicroServiceCardComponent: () => import(
        /* webpackChunkName: "QuitMicroServiceCardComponent" */
        '@/components/MicroServices/QuitMicroService.component.vue'
        ),
    ResubscribeServiceCardComponent: () => import(
        /* webpackChunkName: "ResubscribeServiceCardComponent" */
        '@/components/MicroServices/ResubscribeMicroService.component.vue'
        ),
    StripeConnectActivateComponent: () => import(
        /* webpackChunkName: "StripeConnectActivateComponent" */
        '@/components/Payment/StripeConnectActivate.component.vue'
        ),
  }
})
export default class MicroServicesOverview extends Vue {
  private tabStates = [TAB_STATES.ALL, TAB_STATES.MY];
  private tabStatus = TAB_STATES.ALL;

  private get isShowingAllMicroServices(): boolean {
    return this.tabStatus === TAB_STATES.ALL;
  }

  private onTabStatusChanged(index: number) {
    this.tabStatus = this.tabStates[index];
  }

  /**
   * is called when user clicks on service, passes the id and the service to the summary
   * @param service
   * @private
   */
  private onMicroServiceCardClicked(service: MicroService) {
    this.$router.push({name: ROUTE_MICRO_SERVICE_SUMMARY, params: {id: this.$route.params.id, microserviceId: service.id}});
  }

  /**
   * service that was recently clicked
   * @private
   */
  private clickedService!: MicroService;

  /**
   * flag that handles the detail modal
   * @private
   */
  private showBuyCard: boolean = false;

  private showQuitCard: boolean = false;

  private showResubscribeCard: boolean = false;

  private showStripeConnectActivate: boolean = false;

  /**
   * stores all the loaded micro services
   * @private
   */
  private microServices: MicroService[] = [];

  private bookedMicroServices: MicroService[] = [];

  private get microServicesByState(): MicroService[] {
    switch(this.tabStatus) {
      case TAB_STATES.MY:
        return this.bookedMicroServices;
      default:
        return this.microServices;
    }
  }

  private onPayWithMerwaysActivated(service: MicroService): void {
    this.showStripeConnectActivate = true;
  }

  private async created() {
    await this.loadMicroServices();
  }

  private async loadMicroServices() {
    // fetches the news of the employees
    const response = await MicroserviceRepository.getAll(this.$route.params.id);

    // parses the response as employees and stores them in the store
    this.microServices = response.data.map((n: any) => MicroService.parseFromObject(n));
    this.bookedMicroServices = this.microServices.filter((m) => m.subscriptionStatus === SubscriptionStatus.SUBSCRIBED);
  }

  /**
   * is called when the user clicks on a card, opens detail modal with the clicked card
   * @param service
   * @private
   */
  private onServiceClicked(service: MicroService) {
    this.clickedService = service;
    switch(service.subscriptionStatus) {
      case SubscriptionStatus.NOT_SUBSCRIBED:
        // checks if the type is free and shows the detail view or shows the buy card when the
        // the service is not free
        service.priceType == 'FREE' ? this.onMicroServiceCardClicked(service) : this.showBuyCard = true;
        break;
      case SubscriptionStatus.SUBSCRIBED:
        this.showQuitCard = true;
        break;
      case SubscriptionStatus.CANCELLED:
        this.showResubscribeCard = true;
        break;
    }
  }

  /**
   * is called when the user wants to close the modal, closes the modal and checks if data has changed, if so,
   * reloads the list of employees again to show the updated list of users
   * @param reload
   * @private
   */
  private async closeDialog(reload: boolean) {
    this.showBuyCard = false;
    this.showResubscribeCard = false;
    this.showQuitCard = false;
    this.showStripeConnectActivate = false;
    if (reload) {
      try {
        await this.loadMicroServices();
      } catch (e) {
        this.$handleError(e);
      }
    }
  }
}
